import { Button, Card, Grid, TextField, Typography } from '@mui/material';
import { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import Trash2Fill from '@iconify/icons-eva/trash-2-fill';
import { Icon } from '@iconify/react';
import { AddTreatmentsEntry } from './AddTreatmentsEntry';

EditTreatments.propTypes = {
  onTreatmentsChangeHandler: PropTypes.func.isRequired
};
export function EditTreatments(props) {
  const [currentTreatments, setCurrentTreatments] = useState(props.treatments || []);

  console.log('ct', currentTreatments);
  useEffect(() => {
    props.onTreatmentsChangeHandler(currentTreatments);
  }, [currentTreatments]);

  const handleAddTreatmentEntry = (entry) =>
    setCurrentTreatments([
      ...currentTreatments,
      { key: Math.random().toString(16).slice(2), ...entry }
    ]);
  const handleRemoteTreatmentEntry = (entry) =>
    setCurrentTreatments(currentTreatments.filter((t) => t.key !== entry.key));

  return (
    <>
      <Typography variant="h6" gutterBottom pt={3}>
        Ingresar Tratamientos
      </Typography>

      <Grid
        container
        rowSpacing={5}
        columnSpacing={3}
        columns={{ xs: 6, sm: 6, md: 12 }}
        p={{ xs: 2, sm: 3 }}
      >
        {currentTreatments.map((treatment) => (
          <Fragment key={treatment.key}>
            <Grid item xs={7}>
              <TextField
                InputProps={{
                  readOnly: true
                }}
                label="Nombre"
                value={treatment.name}
                fullWidth
                size="small"
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                InputProps={{
                  readOnly: true
                }}
                label="Precio"
                value={treatment.price}
                fullWidth
                size="small"
              />
            </Grid>
            <Grid item xs={2}>
              <Button
                startIcon={<Icon icon={Trash2Fill} />}
                onClick={() => handleRemoteTreatmentEntry(treatment)}
              >
                Eliminar
              </Button>
            </Grid>
          </Fragment>
        ))}
      </Grid>
      <Card sx={{ boxShadow: 3 }}>
        <AddTreatmentsEntry onSubmit={handleAddTreatmentEntry} />
      </Card>
    </>
  );
}
