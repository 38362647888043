import { gql } from '@apollo/client';

export const updateConsultationMutation = gql`
  mutation updateConsultation(
    $updateConsultationId: ID!
    $consultationData: ConsultationUpdateInput!
  ) {
    updateConsultation(id: $updateConsultationId, consultationData: $consultationData) {
      consultationData {
        event {
          title
          mandatory_presence
          begin
          end
          state
        }
        notes
        payed_amount
        doctor
        amount_to_pay
        total_amount
        consultation_description
        treatments {
          id
          name
          price
        }
      }
      consultationError {
        field
        message
      }
    }
  }
`;
