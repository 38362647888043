import { gql } from '@apollo/client';

export const ConsultationsByPatientId = gql`
  query ConsultationsByPatientId($consultationsByPatientIdId: ID!) {
    consultationsByPatientId(id: $consultationsByPatientIdId) {
      consultation_description
      total_amount
      amount_to_pay
      payed_amount
      patient_id
      event {
        begin
        state
      }
      notes
      consultation_id
      doctor
      treatment_plan_id
    }
  }
`;
