import { useQuery } from '@apollo/client';
import { Box, Card, Skeleton, Stack, Typography } from '@mui/material';
import { filter } from 'lodash';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import { fromUTCdatetimeToLocalDate } from 'utils/formatTime';
import { toCamelCase } from '../../../utils/toCamelCase';
import PaymentListToolbar from '../../payments/PaymentListToolbar';
import PaymentTableContent from '../../payments/PaymentTableContent';
import { revertFormattedDate, getComparator } from '../../../utils/comparator';

const TABLE_HEAD = [
  { id: 'reference', label: 'Referencia', alignRight: false },
  { id: 'paymentDate', label: 'Fecha del pago', alignRight: false },
  { id: 'paymentMethod', label: 'Método de pago', alignRight: false },
  { id: 'payedAmount', label: 'Monto del pago', alignRight: false },
  { id: 'consultationId', label: 'Descripción de la consulta', alignRight: false }
];

// TODO: refactor to utils/comparator.js
function descendingComparator(a, b, orderBy) {
  const checkDateA = revertFormattedDate(a[orderBy]);
  const checkDateB = revertFormattedDate(b[orderBy]);
  if (checkDateA !== -1 && checkDateB !== -1) {
    // This means that a and b are dates
    if (checkDateB.getTime() < checkDateA.getTime()) {
      console.log('-1');
      return -1;
    }
    if (checkDateB.getTime() > checkDateA.getTime()) {
      console.log('1');
      return 1;
    }
    return 0;
  }
  if (b[orderBy] < a[orderBy]) {
    console.log('-1');
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    console.log('1');
    return 1;
  }
  return 0;
}

function applySortFilter(array, comparator, query) {
  const stabilizedArray = array.map((el, index) => [el, index]);
  stabilizedArray.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    return order !== 0 ? order : a[1] - b[1];
  });

  if (query) {
    return filter(array, (payment) => {
      const { reference, consultationDescription, paymentDate, event } = payment;
      const eventDate = event ? fromUTCdatetimeToLocalDate(event.begin) : '';

      return (
        reference.toLowerCase().includes(query.toLowerCase()) ||
        consultationDescription.toLowerCase().includes(query.toLowerCase()) ||
        paymentDate.includes(query) ||
        eventDate.toLowerCase().includes(query.toLowerCase())
      );
    });
  }

  return stabilizedArray.map((el) => el[0]);
}

function formattedDate(d = new Date()) {
  return [d.getDate(), d.getMonth() + 1, d.getFullYear()]
    .map((n) => (n < 10 ? `0${n}` : `${n}`))
    .join('/');
}

PaymentsByConsultation.propTypes = {
  paymentData: PropTypes.object,
  paymentLoading: PropTypes.bool
};

export function PaymentsByConsultation({ paymentData, paymentLoading, ...props }) {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('lastName');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const { consultationId } = useParams();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  if (paymentLoading) {
    return <Skeleton animation="wave" />;
  }

  const payments = consultationId ? toCamelCase(paymentData.paymentByConsultationId || []) : [];
  payments.forEach((payment) => {
    payment.paymentDate = formattedDate(new Date(Number(payment.paymentDate)));
    payment.consultationDate = formattedDate(new Date(Number(payment.consultationDate)));
  });

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - payments.length) : 0;

  const filteredPayments = applySortFilter(payments, getComparator(order, orderBy), filterName);

  const isPaymentNotFound = filteredPayments.length === 0;

  return (
    <Box px={1}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" my={2}>
        <Typography variant="h5" gutterBottom>
          Pagos
        </Typography>
      </Stack>
      <Card sx={{ boxShadow: 3 }}>
        <PaymentListToolbar
          filterName={filterName}
          onFilterName={handleFilterByName}
          placeholder="Buscar pagos..."
          toClose={props.toClose}
        />
        <PaymentTableContent
          isDeletedOnConsultation
          filterName={filterName}
          handleFilterByName={handleFilterByName}
          handleRequestSort={handleRequestSort}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          order={order}
          orderBy={orderBy}
          TABLE_HEAD={TABLE_HEAD}
          filteredPayments={filteredPayments}
          page={page}
          rowsPerPage={rowsPerPage}
          emptyRows={emptyRows}
          isPaymentNotFound={isPaymentNotFound}
        />
      </Card>
    </Box>
  );
}
