import { useQuery } from '@apollo/client';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Skeleton } from '@mui/material';
import { applySortConsultationsFilter, getComparator } from 'utils/comparator';
import { consultationsByTreatmentPlanId } from '../../../graphql/querys/consultationsByTreatmentPlanIdQuery';
import { toCamelCase } from '../../../utils/toCamelCase';

import ConsultationToolbar from '../consultations/ConsultationToolbar';
import ConsultationTableContent from '../consultations/ConsultationTableContent';

const TABLE_HEAD = [
  // THE id here is used to control the sort value, it has to correspond to the value to be compared
  { id: 'event.begin', label: 'Fecha', alignRight: false },
  { id: 'consultationDescription', label: 'Motivo de la consulta', alignRight: false },
  { id: 'event.state', label: 'Estado', alignRight: false }
];

function formattedDate(d = new Date()) {
  return [d.getDate(), d.getMonth() + 1, d.getFullYear()]
    .map((n) => (n < 10 ? `0${n}` : `${n}`))
    .join('/');
}

export function TreatmentPlanConsultations({ treatmentPlanId }) {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('event.begin');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const {
    data: pData,
    loading: consultationsLoading,
    error: consultationsError
  } = useQuery(consultationsByTreatmentPlanId, {
    variables: { consultationsByTreatmentPlanIdId: treatmentPlanId }
  });

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  if (consultationsLoading || !pData) {
    return <Skeleton variant="rectangular" height={200} />;
  }

  const consultations = toCamelCase(pData.consultationsByTreatmentPlanId);
  consultations.forEach((consultation) => {
    consultation.event.begin = formattedDate(new Date(consultation.event.begin));
  });

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - consultations.length) : 0;

  const filteredConsultations = applySortConsultationsFilter(
    consultations,
    getComparator(order, orderBy),
    filterName
  );

  const isConsultationNotFound = filteredConsultations.length === 0;
  return (
    <>
      <Card sx={{ boxShadow: 3 }}>
        <ConsultationToolbar
          filterName={filterName}
          onFilterName={handleFilterByName}
          placeholder="Buscar consulta..."
        />

        <ConsultationTableContent
          filterName={filterName}
          handleRequestSort={handleRequestSort}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          order={order}
          orderBy={orderBy}
          TABLE_HEAD={TABLE_HEAD}
          filteredConsultations={filteredConsultations}
          page={page}
          rowsPerPage={rowsPerPage}
          emptyRows={emptyRows}
          isConsultationNotFound={isConsultationNotFound}
          treatmentPlanId={treatmentPlanId}
        />
      </Card>
    </>
  );
}

TreatmentPlanConsultations.propTypes = {
  treatmentPlanId: PropTypes.string.isRequired
};
