/** Transform recieved object to a form-ready object
  Example of formattedTreatmentPlans value :
  ```
  {
      '432': 'Treatment plan 1',
      '544': 'Treatment plan 2'
  }
  ```
  formattedUsers: initial object to where to add formatted users
  */
export function formatTreatmentPlans(treatmentPlans, formattedTreatmentPlans = {}) {
  treatmentPlans.forEach((tp) => {
    formattedTreatmentPlans = {
      ...formattedTreatmentPlans,
      [tp.id]: tp.name
    };
  });

  return formattedTreatmentPlans;
}
