import { useLazyQuery, useMutation } from '@apollo/client';
import { Alert, Box, Card, Stack, Typography } from '@mui/material';
import { ConsultationsOwedByPatientId } from 'graphql/querys/consultationsOwedByPatientIdQuery';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ConsultationForm } from '../components/_dashboard/consultations/ConsultationForm';
import { insertConsultationMutation } from '../graphql/mutations/insertConsultation';

import { ConsultationsByPatientId } from '../graphql/querys/consultationsByPatientIdQuery';
import { consultationsByTreatmentPlanId } from '../graphql/querys/consultationsByTreatmentPlanIdQuery';
import { generateConsultationDataUploadURL } from '../graphql/querys/generateConsultationDataUploadUrlQuery';
import { treatmentPlansByPatientId } from '../graphql/querys/treatmentPlansByPatientId';
import { putS3 } from '../utils/s3Actions';
import { toSnakeCase } from '../utils/toSnakeCase';

export default function CreateConsultation() {
  const navigate = useNavigate();
  const { id } = useParams();

  const [errors, setErrors] = useState('');
  const [selectedTreatmentPlanId, setSelectedTreatmentPlanId] = useState('indefinido');

  const [generateConsultationDataUploadURLQuery, { error, data: fileData, loading: fileLoading }] =
    useLazyQuery(generateConsultationDataUploadURL);

  const [
    insertConsultation,
    { data: ConsultationData, loading: ConsultationLoading, error: ConsultationError }
  ] = useMutation(insertConsultationMutation, {
    // Then re-run
    refetchQueries: [
      { query: ConsultationsByPatientId, variables: { consultationsByPatientIdId: id } },
      { query: ConsultationsOwedByPatientId, variables: { id } },
      ...(selectedTreatmentPlanId !== 'indefinido'
        ? [
            {
              query: consultationsByTreatmentPlanId,
              variables: { consultationsByTreatmentPlanIdId: selectedTreatmentPlanId }
            },
            {
              query: treatmentPlansByPatientId,
              variables: { id }
            }
          ]
        : [])
    ]
  });

  const onSubmit = async (values, files) => {
    const consultationData = toSnakeCase(values);
    const amountToPay = values.totalAmount;
    console.log(consultationData);
    consultationData.event.patient_id = consultationData.patient_id;
    consultationData.event.mandatory_presence = consultationData.doctor;

    // Casting values to int
    consultationData.amount_to_pay = +amountToPay;
    consultationData.total_amount = +values.totalAmount;
    consultationData.payed_amount = 0;
    consultationData.patient_id = +id;

    const newConsultationData = await insertConsultation({
      variables: { consultationData }
    });

    if (!newConsultationData.data.insertConsultation.consultationError) {
      const newConsultationId =
        newConsultationData.data.insertConsultation.consultationData.consultation_id;

      const urlData = files;
      const fileData = files.map((a) => a.file);
      urlData.map((file) => delete file.file);

      const urlInput = toSnakeCase(urlData);

      const urls = await generateConsultationDataUploadURLQuery({
        variables: {
          consultationId: newConsultationId,
          arrayMetadata: urlInput
        }
      });
      const putResponse = await putS3(fileData, urls.data.generateConsultationDataUploadURL);
      if (!ConsultationError) {
        navigate(`/dashboard/user/${id}`, { replace: true });
      }
    } else if (
      newConsultationData.data.insertConsultation.consultationError.field ===
      'Consultation begin and end date error'
    ) {
      setErrors('La hora de finalizacion debe ser despues de la hora de inicio');
    }
  };

  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5} ml={5}>
        <Typography variant="h4" gutterBottom>
          Nueva consulta
        </Typography>
      </Stack>
      <Box px={{ xs: 1, sm: 2, md: 5 }}>
        <Card sx={{ boxShadow: 3 }}>
          <Box px={{ md: 5 }} py={{ sm: 5 }}>
            <ConsultationForm
              onSubmit={onSubmit}
              patientId={id}
              setSelectedTreatmentPlanId={setSelectedTreatmentPlanId}
            />
            {errors ? (
              <Box py={3}>
                <Alert severity="error">{errors}</Alert>
              </Box>
            ) : null}
          </Box>
        </Card>
      </Box>
    </>
  );
}
