import { filter } from 'lodash';
import { getPropertyByPath } from './getPropertyByPath';

// This function is needed because the browser doesnt recognise dd/mm/yyyy format
export function revertFormattedDate(d) {
  if (!Number.isInteger(d) && d && d.includes('/')) {
    const [day, month, year] = d.split('/');
    return new Date(`${month}/${day}/${year}`);
  }
  return -1;
}

// TODO: refactor to utils/comparator.js
function descendingComparator(a, b, orderBy) {
  const checkDateA = revertFormattedDate(getPropertyByPath(a, orderBy));
  const checkDateB = revertFormattedDate(getPropertyByPath(b, orderBy));
  if (checkDateA !== -1 && checkDateB !== -1) {
    // This means that a and b are dates
    if (checkDateB.getTime() < checkDateA.getTime()) {
      console.log('-1');
      return -1;
    }
    if (checkDateB.getTime() > checkDateA.getTime()) {
      console.log('1');
      return 1;
    }
    return 0;
  }
  if (getPropertyByPath(b, orderBy) < getPropertyByPath(a, orderBy)) {
    console.log('-1');
    return -1;
  }
  if (getPropertyByPath(b, orderBy) > getPropertyByPath(a, orderBy)) {
    console.log('1');
    return 1;
  }
  return 0;
}

export function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function applySortConsultationsFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1]; // this allows us to keep original order in case of similar order values 'A LITTLE OVERKILL'
  });
  //  Pass query as parameter for front side search
  if (query) {
    return filter(
      array,
      (_consultation) =>
        `${_consultation.consultationDescription.toLowerCase()}`.indexOf(query.toLowerCase()) !==
          -1 || `${_consultation.event.begin}`.indexOf(query) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}
