import { gql } from '@apollo/client';

export const deleteTreatmentPlanMutation = gql`
  mutation DeleteTreatmentPlan($treatmentPlanId: ID!) {
    deleteTreatmentPlan(treatmentPlanId: $treatmentPlanId) {
      deleteTreatmentPlanData
      deleteTreatmentPlanError {
        field
        message
      }
    }
  }
`;
