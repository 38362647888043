import { gql } from '@apollo/client';

export const consultationsByTreatmentPlanId = gql`
  query consultationsByTreatmentPlanId($consultationsByTreatmentPlanIdId: ID!) {
    consultationsByTreatmentPlanId(id: $consultationsByTreatmentPlanIdId) {
      consultation_id
      patient_id
      doctor
      event {
        id
        title
        description
        created_by
        last_update_by
        mandatory_presence
        optional_presence
        begin
        end
        created_at
        updated_at
        consultation_id
        state
      }
      notes
      payed_amount
      amount_to_pay
      total_amount
      consultation_description
      treatment_plan_id
      treatments {
        id
      }
    }
  }
`;
