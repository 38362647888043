import { Card } from '@mui/material';
import { useState } from 'react';
import { filter } from 'lodash';
import { getComparator } from 'utils/comparator';
import TreatmentTableContent from './TreatmentTableContent';
import ConsultationToolbar from '../consultations/ConsultationToolbar';

const TABLE_HEAD = [
  // THE id here is used to control the sort value, it has to correspond to the value to be compared
  { id: 'name', label: 'Nombre', alignRight: false },
  { id: 'price', label: 'Precio', alignRight: false }
];

function applySortTreatmentsFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1]; // this allows us to keep original order in case of similar order values 'A LITTLE OVERKILL'
  });
  //  Pass query as parameter for front side search
  if (query) {
    return filter(
      array,
      (_treatment) =>
        `${_treatment.name.toLowerCase()}`.indexOf(query.toLowerCase()) !== -1 ||
        `${_treatment.price}`.indexOf(query) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function TreatmentTable(props) {
  const { treatments } = props;

  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('event.begin');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - treatments.length) : 0;

  const filteredTreatments = applySortTreatmentsFilter(
    treatments,
    getComparator(order, orderBy),
    filterName
  );
  const isTreatmentNotFound = filteredTreatments.length === 0;

  return (
    <Card sx={{ boxShadow: 3 }}>
      <ConsultationToolbar
        filterName={filterName}
        onFilterName={handleFilterByName}
        placeholder="Buscar tratamiento..."
      />

      <TreatmentTableContent
        filterName={filterName}
        handleRequestSort={handleRequestSort}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        order={order}
        orderBy={orderBy}
        TABLE_HEAD={TABLE_HEAD}
        filteredTreatments={filteredTreatments}
        page={page}
        rowsPerPage={rowsPerPage}
        emptyRows={emptyRows}
        isTreatmentsNotFound={isTreatmentNotFound}
        selectable={props.selectable}
        selectedTreatmentsIds={props.selectedTreatmentsIds}
        onSelectChange={props.onSelectChange}
        editingConsultationId={props.editingConsultationId}
        readOnly={props.readOnly}
      />
    </Card>
  );
}
