import { Document, Page as PDFPage, Text, View, Image, StyleSheet } from '@react-pdf/renderer';
import { useQuery } from '@apollo/client';
import { PatientDetailById } from 'graphql/querys/patientDetailByIdQuery';
import palette from 'theme/palette';
import Logo from 'components/Logo';
import { format } from 'date-fns'; // Import date-fns for easy date formatting

// Define styles
const styles = StyleSheet.create({
  page: {
    padding: 30,
    fontFamily: 'Helvetica',
    backgroundColor: 'white'
  },
  header: {
    flexDirection: 'row',
    marginBottom: 40,
    borderBottom: `2 solid ${palette.primary.main}`,
    paddingBottom: 10,
    alignItems: 'center',
    justifyContent: 'space-between' // This will push the date to the right
  },
  logo: {
    width: 200,
    height: 'auto'
  },
  headerContent: {
    flexDirection: 'column',
    alignItems: 'flex-end' // Align items to the right
  },
  headerText: {
    fontSize: 22,
    fontWeight: 'bold',
    color: palette.primary.main
  },
  dateText: {
    fontSize: 12,
    color: palette.text.secondary,
    marginTop: 5
  },
  section: {
    marginBottom: 20,
    padding: 15,
    borderRadius: 8,
    border: `1 solid ${palette.primary.main}`
  },
  sectionTitle: {
    fontSize: 18,
    marginBottom: 10,
    color: palette.common.black,
    borderBottom: `1 solid ${palette.primary.main}`,
    paddingBottom: 5
  },
  fieldName: {
    fontWeight: 'bold',
    fontSize: 14
  },
  fieldValue: {
    fontSize: 12
  }
});

const TreatmentPlanPDF = ({ treatmentPlan, sumOfPrices, patientData }) => {
  const patient = patientData?.patientById;
  const currentDate = format(new Date(), 'dd/MM/yyyy');

  return (
    <Document>
      <PDFPage size="A4" style={styles.page}>
        {/* Header */}
        <View style={styles.header}>
          <Image style={styles.logo} src="/static/dentalByAronin.png" />
          <View style={styles.headerContent}>
            <Text style={styles.headerText}>Presupuesto</Text>
            <Text style={styles.dateText}>{currentDate}</Text>
          </View>
        </View>

        {/* Patient Information */}
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Información del Paciente</Text>
          {patient && (
            <>
              <Text style={styles.fieldValue}>
                <Text style={styles.fieldName}>Nombre:</Text> {patient.first_name}{' '}
                {patient.last_name}
              </Text>
              <Text style={styles.fieldValue}>
                <Text style={styles.fieldName}>Teléfono:</Text> {patient.phone_number}
              </Text>
              <Text style={styles.fieldValue}>
                <Text style={styles.fieldName}>Email:</Text> {patient.email}
              </Text>
            </>
          )}
        </View>

        {/* Treatment Plan Information */}
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Plan de tratamiento</Text>
          <Text style={styles.fieldValue}>
            <Text style={styles.fieldName}>Nombre:</Text> {treatmentPlan.name}
          </Text>
          <Text style={styles.fieldValue}>
            <Text style={styles.fieldName}>Monto total del plan:</Text> ${sumOfPrices}
          </Text>
        </View>

        {/* Treatments */}
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Tratamientos</Text>
          <View style={{ flexDirection: 'row', marginBottom: 5 }}>
            <Text style={[styles.fieldName, { width: '70%' }]}>Nombre del tratamiento</Text>
            <Text style={[styles.fieldName, { width: '30%', textAlign: 'right' }]}>Precio</Text>
          </View>
          {treatmentPlan.treatments.map((treatment, index) => (
            <View key={index} style={{ flexDirection: 'row' }}>
              <Text style={[styles.fieldValue, { width: '70%' }]}>{treatment.name}</Text>
              <Text style={[styles.fieldValue, { width: '30%', textAlign: 'right' }]}>
                ${treatment.price}
              </Text>
            </View>
          ))}
        </View>
      </PDFPage>
    </Document>
  );
};

export default TreatmentPlanPDF;
