import { gql } from '@apollo/client';

export const treatmentPlansByPatientId = gql`
  query treatmentPlansByPatientId($id: ID!) {
    treatmentPlansByPatientId(id: $id) {
      name
      id
      treatments {
        id
        name
        price
        consultation_id
      }
    }
  }
`;
